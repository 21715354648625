<template>
  <v-dialog v-model="dialog" width="75%">
    <v-card>
      <v-card-title> Consulta Renegociação </v-card-title>
      <v-card-subtitle>
        Renegociação feita por {{ negotiation.employee.name }} em
        {{ $format.dateTimeBr(negotiation.created_at) }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <small class="text-secondary">Cliente</small>
            <br />
            <span class="text--primary">
              {{ negotiation.customer.name }} #{{
                negotiation.customer.code
              }}</span
            >
          </v-col>
          <v-col>
            <small class="text-secondary">Motivo</small>
            <br />
            <span class="text--primary"> {{ negotiation.motive }}</span>
          </v-col>
          <v-col>
            <small class="text-secondary">Detalhes</small>
            <br />
            <span class="text--primary">
              {{ negotiation.notes }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>
              Títulos Renegociados ({{
                $format.moneyBr(sum_new_bill_receivables)
              }})
            </h4>
            <v-data-table
              :items="negotiation.new_bill_receivables"
              :headers="headers"
              :items-per-page='-1'
              hide-default-footer
              disable-sort
              dense
            >
              <template v-slot:[`item.issue_date`]="{ item }">
                {{ $format.dateBr(item.issue_date) }}
              </template>
              <template v-slot:[`item.due_date`]="{ item }">
                {{ $format.dateBr(item.due_date) }}
              </template>
              <template v-slot:[`item.value`]="{ item }">
                {{ $format.decimal(item.value) }}
              </template>
              <template v-slot:[`item.paid_value`]="{ item }">
                {{ $format.decimal(item.paid_value) }}
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                {{ $format.decimal(item.discount) }}
              </template>
              <template v-slot:[`item.addition`]="{ item }">
                {{ $format.decimal(item.addition) }}
              </template>
              <template v-slot:[`item.interest`]="{ item }">
                {{ $format.decimal(item.interest) }}
              </template>
              <template v-slot:[`item.debit_balance`]="{ item }">
                {{ $format.decimal(item.value - item.paid_value) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <BillReceivableStatusChip :value="item.status" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>
              Títulos Anteriores ({{
                $format.moneyBr(sum_old_bill_receivables)
              }})
            </h4>
            <v-data-table
              :items="negotiation.old_bill_receivables"
              :headers="headers"
              :items-per-page='-1'
              hide-default-footer
              disable-sort
              dense
            >
              <template v-slot:[`item.document`]="{ item }">
                <span v-if="item.sale_id">
                  {{ item.sale.number }}
                </span>
                <span v-if="!item.sale_id">
                  {{ item.document }}
                </span>
              </template>
              <template v-slot:[`item.issue_date`]="{ item }">
                {{ $format.dateBr(item.issue_date) }}
              </template>
              <template v-slot:[`item.due_date`]="{ item }">
                {{ $format.dateBr(item.due_date) }}
              </template>
              <template v-slot:[`item.value`]="{ item }">
                {{ $format.decimal(item.value) }}
              </template>
              <template v-slot:[`item.paid_value`]="{ item }">
                {{ $format.decimal(item.paid_value) }}
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                {{ $format.decimal(item.discount) }}
              </template>
              <template v-slot:[`item.addition`]="{ item }">
                {{ $format.decimal(item.addition) }}
              </template>
              <template v-slot:[`item.interest`]="{ item }">
                {{ $format.decimal(item.interest) }}
              </template>
              <template v-slot:[`item.debit_balance`]="{ item }">
                {{ $format.decimal(item.value - item.paid_value) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <BillReceivableStatusChip :value="item.status" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BillReceivableStatusChip from "@/components/bill-receivable/sections/BillReceivableStatusChip.vue";

export default {
  components: {
    BillReceivableStatusChip,
  },
  data() {
    return {
      dialog: false,
      negotiation: {
        customer: {},
        employee: {},
        old_bill_receivables: [],
        new_bill_receivables: [],
      },

      headers: [
        { text: "Documento/Venda", value: "document", align: "center" },
        { text: "Emissão", value: "issue_date", align: "center" },
        { text: "Vencimento", value: "due_date", align: "center" },
        { text: "Valor", value: "value", align: "center" },
        { text: "V. Pago", value: "paid_value", align: "center" },
        { text: "Desc", value: "discount", align: "center" },
        { text: "Acréscimo", value: "addition", align: "center" },
        { text: "Juros", value: "interest", align: "center" },
        { text: "Saldo Dev", value: "debit_balance", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
    };
  },

  created() {
    // this.open(6);
  },

  computed: {
    sum_new_bill_receivables() {
      return this.$calc.sum(this.negotiation.new_bill_receivables, "value");
    },
    sum_old_bill_receivables() {
      return this.$calc.sum(this.negotiation.old_bill_receivables, "value");
    },
  },

  methods: {
    open(id) {
      this.$loading.start();
      this.$http
        .show("bill-receivable/bill-receivable-negotiation", id)
        .then((response) => {
          this.negotiation = response.negotiation;
          this.dialog = true;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>