var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"75%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Consulta Renegociação ")]),_c('v-card-subtitle',[_vm._v(" Renegociação feita por "+_vm._s(_vm.negotiation.employee.name)+" em "+_vm._s(_vm.$format.dateTimeBr(_vm.negotiation.created_at))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('small',{staticClass:"text-secondary"},[_vm._v("Cliente")]),_c('br'),_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.negotiation.customer.name)+" #"+_vm._s(_vm.negotiation.customer.code))])]),_c('v-col',[_c('small',{staticClass:"text-secondary"},[_vm._v("Motivo")]),_c('br'),_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.negotiation.motive))])]),_c('v-col',[_c('small',{staticClass:"text-secondary"},[_vm._v("Detalhes")]),_c('br'),_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.negotiation.notes)+" ")])])],1),_c('v-row',[_c('v-col',[_c('h4',[_vm._v(" Títulos Renegociados ("+_vm._s(_vm.$format.moneyBr(_vm.sum_new_bill_receivables))+") ")]),_c('v-data-table',{attrs:{"items":_vm.negotiation.new_bill_receivables,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.issue_date))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.due_date))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.value))+" ")]}},{key:"item.paid_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.paid_value))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.discount))+" ")]}},{key:"item.addition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.addition))+" ")]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.interest))+" ")]}},{key:"item.debit_balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.value - item.paid_value))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('BillReceivableStatusChip',{attrs:{"value":item.status}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('h4',[_vm._v(" Títulos Anteriores ("+_vm._s(_vm.$format.moneyBr(_vm.sum_old_bill_receivables))+") ")]),_c('v-data-table',{attrs:{"items":_vm.negotiation.old_bill_receivables,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.sale_id)?_c('span',[_vm._v(" "+_vm._s(item.sale.number)+" ")]):_vm._e(),(!item.sale_id)?_c('span',[_vm._v(" "+_vm._s(item.document)+" ")]):_vm._e()]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.issue_date))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.due_date))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.value))+" ")]}},{key:"item.paid_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.paid_value))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.discount))+" ")]}},{key:"item.addition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.addition))+" ")]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.interest))+" ")]}},{key:"item.debit_balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.value - item.paid_value))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('BillReceivableStatusChip',{attrs:{"value":item.status}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Voltar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }