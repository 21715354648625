<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span>Analise de Crédito</span>

        <v-btn @click="handleNewCreditAnalyze()" small color="primary">
          Nova Analise
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="credit_analyses.data"
              :items-per-page="-1"
              hide-default-footer
              @click:row="handleNewCreditAnalyze($event.id)"
              disable-sort
              dense
            >
              <template v-slot:[`item.saved_at`]="{ item }">
                {{ $format.dateBr(item.saved_at) }}
              </template>
              <template v-slot:[`item.due_date`]="{ item }">
                {{ $format.dateBr(item.due_date) }}
              </template>
              <template v-slot:[`item.credit_limit`]="{ item }">
                {{ $format.decimal(item.credit_limit) }}
              </template>
              <template v-slot:[`item.credit_status`]="{ item }">
                <b
                  :class="`${creditStatus[item.credit_status].color}--text `"
                  v-text="creditStatus[item.credit_status].text"
                />
              </template>
            </v-data-table>
            <app-pagination @click="select($event)" :data="credit_analyses" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          class="text-capitalize"
          color="secondary"
          text
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>

    <StoreCreditAnalysisForm
      @store="select(), $emit('store')"
      ref="StoreCreditAnalysisForm"
    />
  </v-dialog>
</template>

<script>
import StoreCreditAnalysisForm from "@/components/customer/forms/StoreCreditAnalysisForm.vue";

export default {
  components: {
    StoreCreditAnalysisForm,
  },
  data() {
    return {
      tab: 0,
      dialog: false,

      creditStatus: {
        rejected: { text: "Rejeitado", color: "error" },
        approved: { text: "Aprovado", color: "success" },
      },
      headers: [
        {
          text: " Criado por",
          value: "create_by.name",
          align: "center",
        },
        {
          text: " Realizada em",
          value: "saved_at",
          align: "center",
        },
        {
          text: " Expira em",
          value: "due_date",
          align: "center",
        },
        { text: " Limite de Crédito", value: "credit_limit", align: "center" },
        { text: " Vencida", value: "is_overdue", align: "center" },
        { text: " Situação", value: "credit_status", align: "center" },
      ],
      params: {},
      customer: {},
      credit_analyses: {},
      last_credit_analyses: {},
    };
  },

  methods: {
    async open(customer_id) {
      this.customer_id = customer_id;
      this.select();
      this.dialog = true;
    },

    select(page) {
      this.params.page = page;
      this.params.customer_id = this.customer_id;
      this.$loading.start();
      this.$http
        .index("customer/customer-credit-analysis", this.params)
        .then((response) => {
          this.credit_analyses = response.credit_analyses;
          this.last_credit_analyses = response.last_credit_analyses;

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleNewCreditAnalyze(id) {
      this.$refs.StoreCreditAnalysisForm.open(this.params.customer_id, id);
    },
  },
};
</script>

<style>
</style>